import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Code } from "react-content-loader";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";

import { getAccountBalance, getUser } from "../api";

import { UserContext } from "../context/UserContext";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Input } from "../components/atoms/Input";
import { RetrievalPaymentContext } from "../context/RetrievalPaymentContext";

export function SignUpReview() {
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [password, setPassword] = useState("");

  const { user: userInformation, setUser: setUserInformation } =
    useContext(UserContext);

  const { paymentDetails, updatePaymentDetails } = useContext(RetrievalPaymentContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  async function handleUserInfoRetrieval() {
    const { transactionId } = state;

    const user = await getUser(transactionId).then(
      (response) => response.data
    );

    setInputValues({
      name: user.name[0],
      email: user.email[0],
      address1: user.address[0].address1,
      address2: user.address[0].address2,
      city: user.address[0].city,
      state: user.address[0].state,
      zipCode: user.address[0].zip,
    });

    const userInformation = {
      name: user.name[0],
      email: user.email[0],
      address: {
        address1: user.address[0].address1,
        address2: user.address[0].address2,
        city: user.address[0].city,
        state: user.address[0].state,
        zip: user.address[0].zip,
      },
    };

    setUserInformation(userInformation);
  }

  async function handleBankInfoRetrieval() {
    const { transactionId } = state;

    const accountBalance = await getAccountBalance(transactionId).then(
      (response) => response.data
    );

    const isAccountAlreadyRegistered = paymentDetails.find(account => {
      return account.accountNumber === accountBalance.account.accountNumberLastFour;
    });

    if (!isAccountAlreadyRegistered) {
      const updatedPaymentDetails = {
        transactionId,
        bankName: accountBalance.account.paymentProvider.name,
        bankId: accountBalance.account.providerId,
        accountNumber: accountBalance.account.accountNumberLastFour,
        accountType: accountBalance.account.name,
        accountBalance: accountBalance.amount
      };

      updatePaymentDetails(updatedPaymentDetails);
    }
  }

  function handleEdit() {
    setIsEditing(true);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    navigate("/signup/completed");
  }

  useEffect(() => {
    handleBankInfoRetrieval();
    handleUserInfoRetrieval().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="w-full h-full">
      <Header title={"Manage your finances"} />

      <div className="w-full container px-6 pb-6 mx-auto">
        <Breadcrumbs title={"Manage your finances"} />

        <div className="w-full max-w-md">
          <h1 className="text-2xl font-bold my-6 text-left">
            Review information
          </h1>
          <div className="mb-6">
            <span className="text-neutral-600">
              Please confirm the details below
            </span>
          </div>
          {!isEditing && (
            <div className="rounded-md bg-neutral-100 p-4 mb-6 relative">
              {!loading ? (
                <>
                  <div className="font-semibold mb-2">
                    {userInformation.name}
                  </div>
                  <div>{userInformation?.address?.address1}</div>
                  <span className="mr-1">{userInformation?.address.city},</span>
                  <span className="mr-1">{userInformation?.address.state}</span>
                  <span className="mr-1">{userInformation?.address.zip}</span>
                  <button
                    className="absolute top-4 right-4 hover:opacity-70 transition-opacity"
                    onClick={handleEdit}
                  >
                    <PencilSquareIcon className="fill-neutral-400 w-6" />
                  </button>
                </>
              ) : (
                <Code backgroundColor="#D2D5DA" />
              )}
            </div>
          )}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {isEditing && (
              <>
                <Input id="name" name="Name" type="text" value={inputValues.name} onChangeMethod={handleInputChange}/>
                <Input id="address1" name="Address 1" type="text" value={inputValues.address1} onChangeMethod={handleInputChange}/>

                {inputValues.address2 && (
                  <Input id="address2" name="Address 2" type="text" value={inputValues.address2} onChangeMethod={handleInputChange}/>
                )}

                <Input id="city" name="City" type="text" value={inputValues.city} onChangeMethod={handleInputChange}/>
                <Input id="state" name="State" type="text" value={inputValues.state} onChangeMethod={handleInputChange}/>
                <Input id="zipCode" name="zipCode" type="text" value={inputValues.zipCode} onChangeMethod={handleInputChange}/>
              </>
            )}

            <Input id="email" name="Email address" type="email" value={inputValues.email} onChangeMethod={handleInputChange}/>

            <div className="block relative">
              <input
                type="password"
                className="mt-1 block py-3.5 px-4 text-base w-full rounded-md border border-neutral-400 shadow-sm peer "
                placeholder=" "
                value={password}
                onChange={e => setPassword(e.target.value)}
                id="password"
                required
              />
              <label
                htmlFor="password"
                className="text-neutral-800 text-base absolute duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Password
              </label>
            </div>

            <button
              type="submit"
              disabled={!password}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
            >
              Create account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
