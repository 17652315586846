import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL || "http://localhost:3000" });

export async function getEstablishData(paymentType) {
    const establishData = {
      currency: 'USD',
      amount: '0.00',
      paymentType,
      returnUrl: '#return',
      cancelUrl: '#cancel' 
    };

    try {
      const updatedRequestSignature = await api.post("/signature", establishData)
        .then(response => response.data);
      
      return updatedRequestSignature;

    } catch(error) {
      if (error.response) {
        console.log(error.response);
      }
    }
}

export async function getTransaction(transactionId) {
  return api.get(`/transactions/${transactionId}`);
}

export async function captureTransaction(transactionId, merchantReference, amount) {
  return api.post(`/transactions/${transactionId}/capture`, {
    merchantReference,
    amount
  });
}

export async function getUser(transactionId) {
  return api.get(`/transactions/${transactionId}/user`);
}

export async function getAccountBalance(transactionId) {
  return api.get(`/transactions/${transactionId}/account/balance`);
}