import { Link, useNavigate } from "react-router-dom";

export function Modal({ open, setOpen, scenario }) {
  const navigate = useNavigate();
  const scenarios = {
    digitalWallet: {
      title: "Add funds to digital wallet",
      urls: {
        newUser: "/wallet/new",
        returningUser: "/wallet/returning"
      }
    },
    purchaseSnickers: {
      title: "Purchase Snickers",
      urls: {
        main: "/cart",
        newUser: "/checkout/new",
        returningUser: "/checkout/returning"
      }
    },
  };

  return (
    <div
      className={`relative z-10 ${!open ? "hidden " : ""}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xs z-100`}
          >
            <div className="border border-b-neutral-200 py-5 px-4 flex justify-between items-center">
              <h3
                className="text-base font-semibold leading-5 text-neutral-800"
                id="modal-title"
              >
                { scenarios[scenario].title }
              </h3>
              <button
                className="bg-transparent border-0 text-neutral-400 text-2xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="{1.5}" stroke="#4F565F" className="w-3.5 h-3.5 stroke-2 hover:opacity-80" viewBox="5.5 5.5 13 13">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
            <div className="bg-white px-6 p-5">
              <button
                onClick={() => {
                  if (scenarios[scenario].urls.main) {
                    navigate(scenarios[scenario].urls.main, { state: { urlPath: scenarios[scenario].urls.newUser }})
                  } else {
                    navigate(scenarios[scenario].urls.newUser)
                  }

                }}
                className="inline-flex w-full justify-center rounded border leading-6 border-neutral-600 bg-white px-6 py-3 text-sm font-bold text-neutral-600 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 mb-3 cursor-pointer"
              >
                New user
              </button>
              <button
                onClick={() => {
                  if (scenarios[scenario].urls.main) {
                    navigate(scenarios[scenario].urls.main, { state: { urlPath: scenarios[scenario].urls.returningUser }})
                  } else {
                    navigate(scenarios[scenario].urls.returningUser)
                  }
                }}
                className="inline-flex w-full justify-center rounded border leading-6 border-neutral-600 bg-white px-6 py-3 text-sm font-bold text-neutral-600 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 cursor-pointer"
              >
                Returning user
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
